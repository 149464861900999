<template>
  <div>
    <!-- runat="server" 这个最好加上Iframe跳转 asp.net  可在当前iframe中跳转
    src 链接到的地址URl
    frameborder 是否显示边框（0无边框 1有边框）
    scrolling 是否有滚动条（yes有滚动条 no无滚动条）
    allowtransparency 背景是否透明（yes透明 no不透明） -->
    <iframe :src="printInnerHTML" frameborder="0" scrolling="no" style="height:100%;"></iframe>
  </div>
</template>


<script>
export default {
  name: "printUser",
  // 制作打印模版组件时，props区域尽量保留。
  props: {
    // 打印链接
    printInnerHTML: {
      type: String,
      default: ''
    },
  },
  computed: {
    // 设置高度为窗口高度少100px
    calHeight () {
      return (window.innerHeight - 100) + 'px'
    }
  },
  methods: {
  }
};
</script>

<style scoped>
</style>

